import React, { useState, useEffect } from 'react';
import './AttendanceEventComponent.scss';
import { useSelector, useDispatch } from 'react-redux';


const AttendanceEventComponent = (props) => {
    const header = useSelector((state) => state.header),
      dispatch = useDispatch(),
      [selected, setSelected] = useState(props.selectedEvents),
      [checkedItems, setCheckedItems] = useState({}),
      attendanceEventObject = {
          "Absence" : ["Failure to Notify","Callout","No Punch"],
          "TimeKeeping" : ["Tardy","Late From Lunch","Early Out"]
      };
    
    
    useEffect(() => {
        const initializeCheckedItems = () => {
          const initialState = { all: false };  
    
        if (selected && selected.length > 0 && selected != 'All') {
          Object.keys(attendanceEventObject).forEach((group) => {
            initialState[`all${group}`] = false;  
            attendanceEventObject[group].forEach((item) => {
              initialState[item] = selected.includes(item);
            });
            const allGroupItemsSelected = attendanceEventObject[group].every((item) => initialState[item]);
            initialState[`all${group}`] = allGroupItemsSelected;
          });
          const allSelected = Object.keys(attendanceEventObject).every((group) =>
            attendanceEventObject[group].every((item) => initialState[item])
          );
          initialState.all = allSelected;
        } else {
          initialState.all = true;  
    
          Object.keys(attendanceEventObject).forEach((group) => {
            initialState[`all${group}`] = true;  
            attendanceEventObject[group].forEach((item) => {
              initialState[item] = true;  
            });
          });
        }
        return initialState;
      };
        setCheckedItems(initializeCheckedItems());
      }, []);

      const initializeCheckedItems = (selected) => {
        const initialState = { all: false };
      
        if (selected && selected.length > 0 && selected !== 'All') {
          Object.keys(attendanceEventObject).forEach((group) => {
            initialState[`all${group}`] = false;
            attendanceEventObject[group].forEach((item) => {
              initialState[item] = selected.includes(item);
            });
            initialState[`all${group}`] = attendanceEventObject[group].every((item) => initialState[item]);
          });
          initialState.all = Object.keys(attendanceEventObject).every((group) =>
            attendanceEventObject[group].every((item) => initialState[item])
          );
        } else {
          initialState.all = true;
          Object.keys(attendanceEventObject).forEach((group) => {
            initialState[`all${group}`] = true;
            attendanceEventObject[group].forEach((item) => {
              initialState[item] = true;
            });
          });
        }
      
        return initialState;
      };


      // useEffect(() => {
      //   setCheckedItems(initializeCheckedItems(header.attendanceEvent));
      //  }, [header.attendanceEvent]);



    const renderCustomCheckbox = (checked) => (
        <div className={`custom-checkbox ${checked ? "checked" : ""}`} />
    );

    const handleGroupToggle = (group, items) => {
        const allChecked = items.every((item) => checkedItems[item]);
        const newItemsState = items.reduce((acc, item) => {
          acc[item] = !allChecked;
          return acc;
        }, {});
    
        setCheckedItems((prevState) => {
          const updatedState = {
            ...prevState,
            [`all${group}`]: !allChecked,
            ...newItemsState,
          };
    
          // Check if all groups are checked
          const allGroupsChecked = Object.keys(attendanceEventObject).every((groupKey) =>
            attendanceEventObject[groupKey].every((groupItem) => updatedState[groupItem])
          );
          updatedState.all = allGroupsChecked;
          
          const selectedAttendanceEventArray = [];
          Object.entries(updatedState).forEach(([key, value]) => {
                if(!key.startsWith("all") && value){
                    selectedAttendanceEventArray.push(key);
                    }
                }   
            );
            setSelected(selectedAttendanceEventArray);
            props.dispatchAttendanceEventsSelected(selectedAttendanceEventArray);
          return updatedState;
        });

      };

      const handleCheckboxChange = (item, group) => {
        setCheckedItems((prevState) => {
          const updatedState = {
            ...prevState,
            [item]: !prevState[item],
          };
    
          // Check if all items in the group are checked
          const allGroupItemsChecked = attendanceEventObject[group].every(
            (groupItem) => updatedState[groupItem]
          );
          updatedState[`all${group}`] = allGroupItemsChecked;

          // Check if all groups are checked
          const allChecked = Object.keys(attendanceEventObject).every((groupKey) =>
            attendanceEventObject[groupKey].every((groupItem) => updatedState[groupItem])
          );
          updatedState.all = allChecked;

          const selectedAttendanceEventArray = [];
          Object.entries(updatedState).forEach(([key, value]) => {
                if(!key.startsWith("all") && value){
                    selectedAttendanceEventArray.push(key);
                    }
                }   
            );
            setSelected(selectedAttendanceEventArray);
            props.dispatchAttendanceEventsSelected(selectedAttendanceEventArray);
          return updatedState;
        });

      };


      const handleToggleAll = () => {
        const allChecked = !checkedItems.all;
        setCheckedItems((prevState) => {
          const updatedState = {
            all: allChecked,
            ...Object.keys(attendanceEventObject).reduce((acc, group) => {
              acc[`all${group}`] = allChecked;
              attendanceEventObject[group].forEach((item) => {
                acc[item] = allChecked;
              });
              return acc;
            }, {}),
          };
       
          const selectedAttendanceEventArray = [];
          Object.entries(updatedState).forEach(([key, value]) => {
                if(!key.startsWith("all") && value){
                    selectedAttendanceEventArray.push(key);
                    }
                }   
            );
            setSelected(selectedAttendanceEventArray);
            props.dispatchAttendanceEventsSelected(selectedAttendanceEventArray);

          return updatedState;
        });
      };

    console.log(selected,header.attendanceEvent,checkedItems,"KK")
    return (
        <div className="AttendanceEvent">
          <div className={'category-checkbox'}>
            <div class="group-flex" onClick={handleToggleAll}>
              {renderCustomCheckbox(checkedItems.all)}
              <span class="category-label">All</span>
            </div>
          </div>
          <hr/>
          {Object.keys(attendanceEventObject).map((group,index) => (
          <div key={group} className={'category-checkbox'}>
            <div class="group-flex" onClick={() => handleGroupToggle(group, attendanceEventObject[group])}>
              {renderCustomCheckbox(checkedItems[`all${group}`])}
              <span class="category-label"> All {group}</span>
            </div>
            <div className={'sub-category-checkbox'}>
              {attendanceEventObject[group].map((item) => (
                <div key={item} className={'category-checkbox'}>
                  <div class="group-flex" onClick={() => handleCheckboxChange(item, group)}>
                      {renderCustomCheckbox(checkedItems[item])}
                      <span class="category-label">{item}</span>
                  </div>
                </div>
              ))}
            </div>
            {index !== Object.keys(attendanceEventObject).length - 1 && <hr />}
          </div>
          ))}
        </div>

    )

}

export default AttendanceEventComponent;