import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './SummaryContainer.scss';
import * as config from '../../constants/LanguageConfig';
import { BricksLabel, BricksSelector, BricksMenuItem } from '@nexus/bricks-react';
import { setFilterData } from '../../slices/headerSlice';
import Dropdown from '../common/dropdown/Dropdown';

const SummaryCointainer = () => {
    const summary = useSelector((state) => state.summary.summary),
        totalSummary = useSelector((state) => state.summary.summaryTotal),
        header = useSelector((state) => state.header),
        languageConfig = config.languageOptions,
        languageDetails = header.language,
        dispatch = useDispatch(),
        [impactHour,setImpactHour] = useState(0),
        [plannedHour,setPlannedHour] = useState(0),
        [impactShift,setImpactSHift] = useState(0),
        [plannedShift,setPlannedShift] = useState(0),
        [pointBalance,setPointBalance] = useState(0),
        [unExcusedPlannedHours,setUnExcusedPlannedHours] = useState(0),
        [excusedPlannedHours,setExcusedPlannedHours] = useState(0),
        [unExcusedPlannedShifts,setUnExcusedPlannedShifts] = useState(0),
        [excusedPlannedShifts,setExcusedPlannedShifts] = useState(0),
        [summaryDetails,setSummaryDetails] = useState([]),
        [empName,setEmpName] = useState(''),
        [occuranceIndicator,setOccuranceIndicator] = useState('N'),
        [teamMembers, setTeamMembers] = useState([]),
        [teamMember, setTeamMember] = useState(''),
        [status, setStatus] = useState([]),
        [formattedEmployeeList, setFormattedEmployeeList] = useState({});

    useEffect(()=>{
        setSummaryDetails(summary);
    },[summary])

    useEffect(()=>{
        setPointBalance((summaryDetails.length > 0) ? summaryDetails[0].Point_Balance_Val : 0);
        setEmpName((summaryDetails.length > 0) ? summaryDetails[0].Emp_Name : '');
        setOccuranceIndicator((summaryDetails.length > 0) ? summaryDetails[0].Exclude_Occurrence_Ind : 'N');
        let total_hours_impacted = summaryDetails.reduce((sum, obj) => sum + obj.Tot_Impacted_Hours_Val, 0);
        let total_shift_impacted = summaryDetails.reduce((sum, obj) => sum + obj.Tot_Impacted_Shift_Cnt, 0);

        setImpactHour(total_hours_impacted);
        setImpactSHift(total_shift_impacted)
    },[summaryDetails])

    useEffect(() => {
        let totalSummaryDetails = totalSummary;
        setPlannedHour((totalSummaryDetails.length > 0)?totalSummaryDetails[0].Tot_Planned_Hours_Val : 0)
        setPlannedShift((totalSummaryDetails.length > 0)?totalSummaryDetails[0].Tot_Planned_Shift_Cnt : 0)
    }, [totalSummary]);

    useEffect(() => {
        const sumImpactedHoursExcused = summaryDetails
                    .filter(item => item.Excusal_Type == 'Excused')
                    .reduce((sum, item) => sum + item.Tot_Impacted_Hours_Val, 0);
        const sumImpactedHoursUnexcused = summaryDetails
                    .filter(item => item.Excusal_Type == 'Unexcused')
                    .reduce((sum, item) => sum + item.Tot_Impacted_Hours_Val, 0);
        const sumImpactedShiftsExcused = summaryDetails
                    .filter(item => item.Excusal_Type == 'Excused')
                    .reduce((sum, item) => sum + item.Tot_Impacted_Shift_Cnt, 0);
        const sumImpactedShiftsUnexcused = summaryDetails
                    .filter(item => item.Excusal_Type == 'Unexcused')
                    .reduce((sum, item) => sum + item.Tot_Impacted_Shift_Cnt, 0);

        
        setExcusedPlannedHours((plannedHour && !isNaN(plannedHour) && plannedHour !== 0) ? (sumImpactedHoursExcused / plannedHour) * 100 : 0);
        setUnExcusedPlannedHours((plannedHour && !isNaN(plannedHour) && plannedHour !== 0) ? (sumImpactedHoursUnexcused/plannedHour)*100 : 0);
        setExcusedPlannedShifts((plannedShift && !isNaN(plannedShift) && plannedShift !== 0) ? (sumImpactedShiftsExcused/plannedShift)*100 : 0);
        setUnExcusedPlannedShifts((plannedShift && !isNaN(plannedShift) && plannedShift !== 0) ? (sumImpactedShiftsUnexcused/plannedShift)*100 : 0);
    }, [summaryDetails,plannedHour,plannedShift]);

    const dispatchTeamMember = (selectedMember) => {
        let selectedMemberDetails = header.teamMemberList.find(member => member.Emp_Name == selectedMember)
        dispatch(setFilterData({ state: "selectedMember", value: selectedMemberDetails}));
    }

    const handleTeamChange = (e) => {
        setTeamMember(e.detail.value);
        
   }

   useEffect(() => {
    if(teamMember != '' ){
        let selectedMember = header.teamMemberList.find(member => member.Emp_Name == teamMember)
        dispatch(setFilterData({ state: "selectedMember", value: selectedMember}));
    }
}, [teamMember])

   useEffect(() => {
    let memebers = [...new Set(header.teamMemberList.map(member => member.Emp_Name).sort())];
    setTeamMembers(memebers);

    if(memebers.length > 0){
        let firstMember = header.teamMemberList.find(member => member.Emp_Name == memebers[0])
            if (firstMember) {
                dispatch(setFilterData({ state: "selectedMember", value: firstMember }));
                setTeamMember(firstMember.Emp_Name);
            }
        }else{
            dispatch(setFilterData({ state: "selectedMember", value: '' }));
        }
    
    const formattedEmpListOutput = header.teamMemberList.reduce((acc, employee) => {
        acc[employee.Emp_Name] = {
            JOB_TYPE_CD: employee.JOB_TYPE_CD,
            Job_Title_Desc: employee.Job_Title_Desc
        };
        return acc;
    }, {});

    setFormattedEmployeeList(formattedEmpListOutput)
}, [header.teamMemberList])

useEffect(() => {
    if (Object.keys(header.selectedMember).length == 0) {
        setSummaryDetails([]);
        setPlannedHour(0);
        setPlannedShift(0)
    }
  }, [header.selectedMember])

    return (
        <>
            <div className='summary'>
                <div className="summary-label">{languageDetails[languageConfig.Summary]}-{empName}</div>

                {header.corporateEmployee ? (<div className='"sub-section selector-scroll'>
                        {/* <BricksLabel id="team-member">Team Member</BricksLabel> */}
                        <Dropdown
                        data={teamMembers}
                        formattedTeams = {formattedEmployeeList}
                        isSingleSelection={true}
                        label="Team Member"
                        selected={empName}
                        dispatchSelection={dispatchTeamMember}
                        searchPlaceHolder="Search Team Member" />
                        {/* <BricksSelector filter accessibleTitle="Team Member" value={teamMember} onBricksSelectorChange={handleTeamChange}>
                            {teamMembers.map((team) => (
                                <BricksMenuItem key={team} value={team} displayLabel={team}>
                                    <div class="radio-content">
                                        <span class="title">{team}</span>
                                        <span class="team-description">{formattedEmployeeList[team].Job_Title_Desc} - {formattedEmployeeList[team].JOB_TYPE_CD}</span>
                                    </div>
                                </BricksMenuItem>
                            ))}
                        </BricksSelector> */}

                    </div>): null}
            </div>
            <div className='summary-card-container'>
            <div className="card card-hour">
                    <div className="card-header">{languageDetails[languageConfig.Hours]}</div>
                    <div className="card-content row">
                        <div className="card-col">
                            <div className='card-col-header'>Impacted<br /> Hours </div>
                            <span >{impactHour.toFixed(0)}</span>

                        </div>
                        <div className="card-col">
                            <div className='card-col-header'>Planned<br /> Hours </div>
                            <span >{(plannedHour != 0) ? plannedHour.toFixed(0) : 0}</span>{" "}

                        </div>
                        <div className="card-col">
                            <div className='card-col-header'>% Impact to Planned Hours </div>
                            <span>{(plannedHour != 0) ? ((impactHour / plannedHour) * 100).toFixed(0) : 0}{'%'}</span>

                        </div>
                    </div>
                </div>
                <div className="card card-hour">
                    <div className="card-header">{languageDetails[languageConfig.Shifts]}</div>
                    <div className="card-content row">
                        <div className="card-col">
                            <div className='card-col-header'>Impacted<br /> Shifts </div>
                            <span >{impactShift.toFixed(0)}</span>

                        </div>
                        <div className="card-col">
                            <div className='card-col-header'>Planned<br /> Shifts </div>
                            <span >{(plannedShift != 0) ? plannedShift.toFixed(0): 0}</span>{" "}

                        </div>
                        <div className="card-col">
                            <div className='card-col-header'>% Impact to Planned Shifts </div>
                            <span>{(plannedShift != 0) ? ((impactShift / plannedShift) * 100).toFixed(0) : 0}{'%'}</span>

                        </div>
                    </div>
                </div>
                <div className="card card-hour">
                    <div className="card-header">{languageDetails[languageConfig['Impact by Excusal Type']]}</div>
                    <div className="card-content row">
                        <div class="impact-section">
                            <div className="card-col-header">% Impact to <br/>Planned Hours</div>
                            <div class="impact-values">
                                <div class="impact-value">
                                    <p class="percentage">{unExcusedPlannedHours.toFixed(0)}%</p>
                                    <p class="label">Unexcused</p>
                                </div>
                                <div class="impact-value">
                                    <p class="percentage">{excusedPlannedHours.toFixed(0)}%</p>
                                    <p class="label">Excused</p>
                                </div>
                            </div>
                        </div>
                        <div class="impact-section">
                        <div className="card-col-header">% Impact to <br/>Planned Shifts</div>
                            <div class="impact-values">
                                <div class="impact-value">
                                    <p class="percentage">{unExcusedPlannedShifts.toFixed(0)}%</p>
                                    <p class="label">Unexcused</p>
                                </div>
                                <div class="impact-value">
                                    <p class="percentage">{excusedPlannedShifts.toFixed(0)}%</p>
                                    <p class="label">Excused</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(occuranceIndicator == 'N') ?
                <div className="card card-occurance">
                    <div className="card-header">{languageDetails[languageConfig.Occurrences]}</div>
                    <div className="card-content">
                        <div className="card-col">
                            <div className='card-col-header-occurance'>Balance as of Today</div>
                            <span >{pointBalance}</span>

                        </div>
                    </div>
                </div>
                :null}
            
            </div>
        </>
    )
}

export default SummaryCointainer;